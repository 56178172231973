@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("./login.css");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
/* custom */
.font-montseraat {
  font-family: "Montserrat", sans-serif;
}
.ant-menu .ant-menu-item-selected {
  background-color: #620926;
}

.ant-btn-primary {
  background-color: #620926;
  color: #fff;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  /* color: #620926; */
  border-color: #620926;
}
/* :where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  border-color: #fff;
} */
.colors-picker-container .ant-form-item-control-input-content {
  display: flex;
  align-items: self-start;
}
