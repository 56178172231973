#login-form {
    max-width: 450px;
    width: 100%;
    padding: 60px;
    background-color: #fff;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  #login-form p {
    margin-bottom: 30px;
  }
  
  #login-form .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }
  .ant-form-item-control-input-content {
    text-align: left;
  }
  #login-form .ant-input-affix-wrapper {
    padding: 12px 15px;
  }
  #login-form_email {
    height: 48px;
  }
  #login-form .ant-btn {
    height: 42px;
    letter-spacing: 1px;
    border-radius: 6px;
  }
  
  @media screen and (max-width: 1023px) {
    #login-form {
      width: 100%;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }
  }